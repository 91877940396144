import { Address, VerisChain } from "@unlockdfinance/verislabs-web3";
import env from "env";
import { CollectionData } from "logic/types/collection/ICollection";
import { CurrencyData } from "logic/types/currency/ICurrency";
import { mainnetConfig } from "mainnet.config";
import { sepoliaApp } from "sepolia.config";

export type HexId = "0x5" | "0x1" | "0xaa36a7";
export type ConfigChain = { name: VerisChain; hexId: HexId; id: number };

export type NetworkConfig = {
  PUNKS_COLLECTION: CollectionData;
  FAUCET_COLLECTIONS: CollectionData[];
  COLLECTIONS: CollectionData[];
  CONTRACT_ADDRESSES: {
    theLockeys: Address;
    theLockeysMainnet: Address;
    weth: Address;
    pushChannel: Address;
    unlockd: Address;
    uTokenFactory: Address;
    lockeyManager: Address;
    walletFactory: Address;
    walletRegistry: Address;
    bulkTransfer: Address;
    unlockdHelper: Address;
    earnWethGateway: Address;
  };
  ALCHEMY: {
    rpcUrl: string;
    apiUrl: string;
  };
  CHAIN: ConfigChain;
  SMART_ACCOUNTS_CONFIG: {
    delegationController: Address;
  };
  FUNCTIONALITIES: {
    faucet: boolean;
    "eth-wrapper": boolean;
  };
  CURRENCIES: CurrencyData[];
  TOKENS: CurrencyData[];
};

export type App = NetworkConfig & {
  UNLOCKD_MODULES: {
    INSTALLER: string;
    MANAGER: string;
    ACTION: string;
    AUCTION: string;
    MARKET: string;
    BUYNOW: string;
    SELLNOW: string;
  };
  ALCHEMY: {
    mainnetRpcUrl: string;
  };
  MAX_NFTS_FOR_A_USER: number;
  TOKEN_ID_RANGES_TEAM: string;
  TOKEN_ID_RANGES_KEY_SUPPORTER: string;
  TOKEN_ID_RANGES_ONE_ON_ONE: string;
  DATE_TO_FAUCET_TEAM: string;
  DATE_TO_FAUCET_ONE_ON_ONE: string;
  DATE_TO_FAUCET_KEY_SUPPORTER: string;
  DATE_TO_FAUCET_HOLDER: string;
  DATE_TO_FAUCET_NON_HOLDER: string;
  DEFAULT_LIQ_THRESHOLD_VALUE: bigint;
  WALLET_CONNECT: {
    projectId: string;
  };
};

const networkConfig: NetworkConfig =
  env.isDevelopment || env.isLocal ? sepoliaApp : mainnetConfig;

export const app: App = {
  ...networkConfig,
  ALCHEMY: {
    mainnetRpcUrl: "https://eth-mainnet.g.alchemy.com/v2",
    ...networkConfig.ALCHEMY,
  },
  UNLOCKD_MODULES: {
    INSTALLER: "1",
    MANAGER: "2",
    ACTION: "3",
    AUCTION: "4",
    MARKET: "5",
    BUYNOW: "6",
    SELLNOW: "7",
  },
  MAX_NFTS_FOR_A_USER: 2,
  TOKEN_ID_RANGES_TEAM: "51/75|1453/1459|1983/1983|668/668",
  TOKEN_ID_RANGES_KEY_SUPPORTER: "76/590",
  TOKEN_ID_RANGES_ONE_ON_ONE: "1/50",
  DATE_TO_FAUCET_TEAM: "12/01/2022",
  DATE_TO_FAUCET_ONE_ON_ONE: "12/06/2022",
  DATE_TO_FAUCET_KEY_SUPPORTER: "12/06/2022",
  DATE_TO_FAUCET_HOLDER: "12/06/2022",
  DATE_TO_FAUCET_NON_HOLDER: "12/15/2022",
  DEFAULT_LIQ_THRESHOLD_VALUE: BigInt(8500),
  WALLET_CONNECT: {
    projectId: "ec934ada88527af0e297054305451159",
  },
};

export function isFaucetEnabled() {
  return app.FUNCTIONALITIES.faucet;
}

export function isMainnet() {
  return env.isProduction;
}

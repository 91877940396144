import { Alchemy, Network } from "alchemy-sdk";
import { app } from "../app.config";
import env from "env";

const getNetwork = () => {
  switch (app.CHAIN.name) {
    case "goerli":
      return Network.ETH_GOERLI;
    case "sepolia":
      return Network.ETH_SEPOLIA;
    case "mainnet":
      return Network.ETH_MAINNET;
  }
};

export const alchemyApiKey =
  typeof window === "undefined"
    ? process.env.SERVER_ALCHEMY_KEY!
    : env.publicAlchemyApiKey;

const settings = {
  apiKey: alchemyApiKey,
  network: getNetwork(),
};

export const alchemy = new Alchemy(settings);

// temporary setting to be remove when the dapp is deploy on mainnet
// reminder: retrieveLockeysHolderType file has to be modified also at these time
const settingsMainnet = {
  apiKey:
    typeof window === "undefined"
      ? process.env.SERVER_ALCHEMY_KEY
      : env.publicAlchemyApiKey,
  network: Network.ETH_MAINNET,
};

//@ts-ignore
alchemy.TEMP_MAINNET = new Alchemy(settingsMainnet);

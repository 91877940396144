import { Address } from "viem";

export default abstract class AddressProvider {
  map: { [key: string]: Address };
  private static instance: AddressProvider;

  constructor(contractAddresses: { [key: string]: Address }) {
    this.map = contractAddresses;
  }

  static init(instance: AddressProvider) {
    this.instance = instance;
  }

  static get(): AddressProvider {
    return this.instance;
  }

  async getAddress(id: string): Promise<Address> {
    if (!this.map[id]) {
      const address = await this.lookupAddress(id);

      this.map[id] = address;
    }

    return this.map[id];
  }

  abstract lookupAddress(...args: any[]): Promise<Address>;
}

import {
  Abi,
  Address,
  PublicClient,
  WalletClient,
  getContract,
  GetContractReturnType,
} from "viem";
import { encodeFunctionData } from "viem";
import AddressProvider from "./helpers/AddressesProvider";
import IVerisModule from "../IVerisModule";

export default abstract class VerisContract<TAbi extends Abi> {
  private _address?: Address;
  private _abi: TAbi;
  private _readonlyClient: any;
  private _writeClient: any;
  private _viemReadContract: any;
  private _viemWriteContract: any;
  private _moduleId?: string;
  verisModule: IVerisModule;

  constructor(
    verisModule: IVerisModule,
    { address, moduleId }: { address?: Address; moduleId?: string },
    abi: TAbi
  ) {
    if (!address && !moduleId)
      throw new Error(
        "Contract must be initialized with either an address or a moduleId"
      );

    if (address) this._address = address;
    if (moduleId) this._moduleId = moduleId;
    this._abi = abi;
    this.verisModule = verisModule;
  }

  get viemWriteContract(): Promise<
    GetContractReturnType<TAbi, WalletClient, Address>
  > {
    return new Promise(async (resolve) => {
      const writeClient = this.verisModule.lookUpClient("write");

      if (writeClient === this._writeClient && this._viemWriteContract) {
        resolve(
          this._viemWriteContract as GetContractReturnType<
            TAbi,
            WalletClient,
            Address
          >
        );
      } else {
        this._writeClient = writeClient;

        const config: {
          abi: Abi;
          address: Address;
          client: {
            public: PublicClient;
            wallet?: WalletClient;
          };
        } = {
          address: await this.getAddress(),
          abi: this.abi,
          client: {
            public: writeClient as PublicClient,
            wallet: writeClient as WalletClient,
          },
        };

        const contract = getContract(config);

        this._viemWriteContract = contract;

        resolve(
          contract as unknown as GetContractReturnType<
            TAbi,
            WalletClient,
            Address
          >
        );
      }
    });
  }

  get viemReadContract(): Promise<
    GetContractReturnType<TAbi, PublicClient, Address>
  > {
    return new Promise(async (resolve) => {
      // TODO: CHECK HOW TO HANDLE THIS
      //     const readonlyClient = lookUpClient(
      //     CHAIN.id !== 1 && this._address === CONTRACT_ADDRESSES.theLockeysMainnet
      //       ? "mainnet"
      //       : "readOnly"
      //   );

      const readonlyClient = this.verisModule.lookUpClient("readOnly");

      if (readonlyClient === this._readonlyClient && this._viemReadContract) {
        resolve(
          this._viemReadContract as GetContractReturnType<
            TAbi,
            PublicClient,
            Address
          >
        );
      } else {
        this._readonlyClient = readonlyClient;

        const config: {
          abi: Abi;
          address: Address;
          client: { public: PublicClient; wallet?: WalletClient };
        } = {
          address: await this.getAddress(),
          abi: this.abi,
          client: { public: readonlyClient as PublicClient },
        };

        const contract = getContract(config);

        this._viemReadContract = contract;

        resolve(
          contract as unknown as GetContractReturnType<
            TAbi,
            PublicClient,
            Address
          >
        );
      }
    });
  }

  private async getAddress() {
    if (!this._address) {
      this._address = await AddressProvider.get().getAddress(this._moduleId!);
    }

    return this._address!;
  }

  get address() {
    return this._address;
  }

  get abi() {
    return this._abi;
  }

  encodeFunctionData(functionName: string, args?: any[]): string {
    return encodeFunctionData({ abi: this.abi as Abi, args, functionName });
  }
}

import { Address, erc20Abi } from "viem";
import VerisContract from "./VerisContract";
import IVerisModule from "../IVerisModule";
import { OptionsWriteMethod } from "../types";
import { Output } from "../Output";

export default class Erc20Contract extends VerisContract<typeof erc20Abi> {
  constructor(verisModule: IVerisModule, address: Address) {
    super(verisModule, { address }, erc20Abi);
  }

  async balanceOf(address: Address) {
    return (await this.viemReadContract).read.balanceOf([address]);
  }

  async allowance(owner: Address, spender: Address) {
    return (await this.viemReadContract).read.allowance([owner, spender]);
  }

  async approve(
    spender: Address,
    amount: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    return this.verisModule.walletModule.handleViemTransaction(
      async () =>
        // @ts-ignore
        (await this.viemWriteContract).write.approve([spender, amount]),
      options
    );
  }
}

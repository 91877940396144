import { Chain, createPublicClient, http, TransactionReceipt } from "viem";
import { goerli, mainnet, sepolia } from "@wagmi/core/chains";
import { WalletModule } from "./WalletModule";
import {
  Config,
  GetPublicClientReturnType,
  createConfig,
  injected,
} from "@wagmi/core";
import { walletConnect } from "@wagmi/connectors";
import { Address, Hash, VerisChain } from "./types";
import IVerisModule from "./IVerisModule";
import VerisStorage from "./utils/VerisStorage";
import { Output } from "./Output";
import SafeApiKit from "@safe-global/api-kit";
import { ALCHEMY } from "./constants";
import ChainlinkPriceFeed from "./contracts/ChainlinkPriceFeed";
import { CONTRACT_ADDRESSES, NetworkAddresses } from "./networkConfig/config";

const getChain = (verisChain: VerisChain): Chain => {
  switch (verisChain) {
    case VerisChain.SEPOLIA:
      return sepolia;
    case VerisChain.GOERLI:
      return goerli;
    case VerisChain.MAINNET:
      return mainnet;
  }
};

export default class VerisModule implements IVerisModule {
  _alchemyApiKey: string;
  _wcProjectId: string;
  _chain: Chain;
  wagmiConfig: Config;
  safeService: SafeApiKit;
  publicClient: GetPublicClientReturnType;
  publicMainnetClient: GetPublicClientReturnType;
  walletModule: WalletModule;
  storage: {
    local: VerisStorage | null;
    session: VerisStorage | null;
  };
  networkAddresses: NetworkAddresses;

  constructor(chain: VerisChain, alchemyApiKey: string, wcProjectId: string) {
    this._alchemyApiKey = alchemyApiKey;
    this._wcProjectId = wcProjectId;
    this._chain = getChain(chain);

    this.networkAddresses = CONTRACT_ADDRESSES[chain];

    this.publicClient = createPublicClient({
      chain: this._chain,
      transport: http(`${ALCHEMY[chain].rpcUrl}/${this._alchemyApiKey}`),
    });

    this.publicMainnetClient = createPublicClient({
      chain: mainnet,
      transport: http(`${ALCHEMY[VerisChain.MAINNET]}/${this._alchemyApiKey}`),
    });

    this.wagmiConfig = createConfig({
      connectors: [injected(), walletConnect({ projectId: this._wcProjectId })],
      chains: [this._chain],
      client: () => {
        return this.publicClient;
      },
    });

    if (typeof window !== "undefined") {
      this.storage = {
        local: new VerisStorage(this, localStorage),
        session: new VerisStorage(this, sessionStorage),
      };
    } else {
      this.storage = {
        local: null,
        session: null,
      };
    }

    this.safeService = new SafeApiKit({
      chainId: BigInt(this._chain.id),
    });

    this.walletModule = new WalletModule(this);
  }

  get chainName(): string {
    return this._chain.name;
  }

  lookUpClient(type: "readOnly" | "mainnet" | "write") {
    if (type === "write") {
      if (!this.walletModule.client) {
        throw new Error("Wallet module client is not initialized");
      }

      return this.walletModule.client;
    } else if (type === "mainnet") {
      return this.publicMainnetClient;
    } else {
      return this.publicClient;
      // return externalWalletModule.connector?.name === "MetaMask" &&
      //   externalWalletModule.client
      //   ? externalWalletModule.client
      //   : publicClient;
    }
  }

  async waitForTransactionReceipt<TData>(
    hash: Hash,
    topic?: Hash
  ): Promise<Output<TData>> {
    const client = this.lookUpClient("readOnly");

    return new Promise<Output<TData>>(async (resolve, reject) => {
      const txReceipt: TransactionReceipt =
        // @ts-ignore
        await client.waitForTransactionReceipt({
          hash,
        });

      if (txReceipt && txReceipt.status === "success") {
        let log;

        if (topic) {
          log = txReceipt.logs.find((log) => log.topics[0] === topic);

          if (!log) reject(new Error("log not found"));
        }

        resolve(new Output(txReceipt.transactionHash, log));
      } else {
        reject(new Error("transaction failed"));
      }
    });
  }

  async getDollarRate(): Promise<bigint> {
    const chainlinkContract = new ChainlinkPriceFeed(
      this,
      this.networkAddresses.chainLinkEthUsdPriceFeed
    );

    return chainlinkContract.latestAnswer();
  }

  async waitForSafeExecution(
    smartAddress: Address,
    safeTxHash: Address,
    pollingTime: number = 5000
  ): Promise<Hash> {
    return new Promise<Hash>((resolve, reject) => {
      const getTransactionHash = async (hash: Hash) => {
        try {
          const tx = await this.safeService.getTransaction(hash);

          if (!tx.transactionHash) {
            const allTxs = await this.safeService.getAllTransactions(
              smartAddress
            );

            const txsWithSameNonce = allTxs.results.filter(
              // @ts-ignore
              (_tx) => _tx.nonce === tx.nonce
            );

            if (
              txsWithSameNonce.length > 1 &&
              txsWithSameNonce.some(
                // @ts-ignore
                (_tx) => _tx.data === null && _tx.isExecuted
              )
            ) {
              reject(new Error("Transaction rejected"));
            }

            setTimeout(() => getTransactionHash(hash), pollingTime);
          } else {
            resolve(tx.transactionHash as Hash);
          }
        } catch (err) {
          reject(err);
        }
      };

      getTransactionHash(safeTxHash);
    });
  }
}

import { VerisChain } from "./types";

type AlchemyConstants = {
  [key in VerisChain]: { rpcUrl: string; apiUrl: string };
};

export const ALCHEMY: AlchemyConstants = {
  sepolia: {
    rpcUrl: "https://eth-sepolia.g.alchemy.com/v2",
    apiUrl: "https://eth-sepolia.g.alchemy.com/nft/v2",
  },
  goerli: {
    rpcUrl: "https://eth-goerli.g.alchemy.com/v2",
    apiUrl: "",
  },
  mainnet: {
    rpcUrl: "https://eth-mainnet.g.alchemy.com/v2",
    apiUrl: "",
  },
};

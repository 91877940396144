import { app } from "app.config";
import { ConnectionError } from "../errors/errors";
import { equalIgnoreCase } from "@unlockdfinance/verislabs-web3/utils";
import { alchemyApiKey } from "clients/alchemy";
// import { isCryptopunks } from "../logic/helpers";

type Metadata = {
  name: string;
  image: string;
  attributes: Attribute[];
};

type Attribute = {
  value: string;
  trait_type: string;
};

export type NftMetadata = {
  contract: {
    address: string;
  };
  id: {
    tokenId: string;
    tokenMetadata: {};
  };
  balance: string;
  title: string;
  description: string;
  tokenUri: {
    raw: string;
    gateway: string;
  };
  contractMetadata: {
    name: string;
  };
  media: Array<{
    raw: string;
    gateway: string;
  }>;
  metadata: Metadata;
};

export const getNftsMetadataBatch = async (
  tokens: Array<{ contractAddress: string; tokenId: string }>,
  options?: { maxRetries: number }
): Promise<Array<NftMetadata>> => {
  const url = `${app.ALCHEMY.apiUrl}/${alchemyApiKey}/getNFTMetadataBatch`;

  if (app.CHAIN.name === "goerli" || app.CHAIN.name === "sepolia") {
    tokens = tokens.map(({ contractAddress, tokenId }) => ({
      contractAddress,
      tokenId,
    }));
  }

  const body = {
    tokens,
    tokenUriTimeoutInMs: 500,
    refreshCache: true,
  };

  const data = await (async function call(
    retriesCount: number
  ): Promise<Array<any>> {
    const response = await fetch(url, {
      method: "POST",
      body: JSON.stringify(body),
      headers: {
        "content-type": "application/json",
      },
    });

    if (response.ok) {
      const data: Array<NftMetadata> = await response.json();

      return tokens.map(({ contractAddress, tokenId }) => {
        const item = data.find(
          (nft) =>
            equalIgnoreCase(nft.contract.address, contractAddress) &&
            tokenId === nft.id.tokenId
        );

        return item;
      });
    }

    if (response.status > 499 && retriesCount < (options?.maxRetries || 3)) {
      return call(retriesCount + 1);
    }

    throw new ConnectionError("failed fetching nfts metadata from alchemy");
  })(1);

  return data;
};

export enum VerisChain {
  SEPOLIA = "sepolia",
  GOERLI = "goerli",
  MAINNET = "mainnet",
}

export type Hash = `0x${string}`;
export type Address = `0x${string}`;

export type OptionsWriteMethod = {
  onServerSignPending?: () => void;
  onSignaturePending?: () => void;
  onLoading?: (txHash?: Hash) => void;
  topicFilter?: Hash;
};

import { Address, VerisChain } from "../types";

export type NetworkAddresses = {
  chainLinkEthUsdPriceFeed: Address;
};

export type NetworksAddresses = {
  [key in VerisChain]: NetworkAddresses;
};

export const CONTRACT_ADDRESSES: NetworksAddresses = {
  sepolia: {
    chainLinkEthUsdPriceFeed: "0x694AA1769357215DE4FAC081bf1f309aDC325306",
  },
  goerli: {
    chainLinkEthUsdPriceFeed: "0xD4a33860578De61DBAbDc8BFdb98FD742fA7028e",
  },
  mainnet: {
    chainLinkEthUsdPriceFeed: "0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419",
  },
};

import {
  Connector,
  ConnectorAlreadyConnectedError,
  GetAccountReturnType,
  connect as _connect,
  disconnect as _disconnect,
  getAccount,
  getBalance,
  getConnectors,
  getWalletClient,
  reconnect,
  switchChain,
  watchAccount,
} from "@wagmi/core";
import { Address, Chain as ViemChain, WalletClient, publicActions } from "viem";
import IVerisModule from "./IVerisModule";
import Erc20Contract from "./contracts/Erc20Contract";
import { Hash, OptionsWriteMethod } from "./types";
import { Output } from "./Output";
import track from "logic/track";

export type WalletSubsCallback = (
  address: Address | null,
  chainId: number | null,
  client: WalletClient | null,
  connector: Connector | null
) => void;

export type CustomConnector = Connector & {
  connect: () => Promise<{
    accounts: readonly `0x${string}`[];
    chainId: number;
  }>;
};

type Balance = {
  value: bigint;
  timestamp: number;
};

export class WalletModule {
  private subscriptions: WalletSubsCallback[] = [];
  private _client: WalletClient | null = null;
  private _address: Address | null = null;
  private _chainId: number | null = null;
  private _connector: Connector | null = null;
  private balance: Balance | null = null;
  private erc20Balances: Record<Address, Balance> = {};
  private verisModule: IVerisModule;

  constructor(verisModule: IVerisModule) {
    this.verisModule = verisModule;

    if (typeof window !== "undefined") {
      const unwatch = watchAccount(this.verisModule.wagmiConfig, {
        onChange: this.onChange,
      });

      this.reconnect();
    }
  }

  private reconnect = async () => {
    try {
      if (this.connectedConnector) {
        await reconnect(this.verisModule.wagmiConfig, {
          connectors: this.getConnectors().filter(
            (connector) => connector.name === this.connectedConnector
          ),
        });
      }
    } catch (e) {
      // console.log("error", e);

      this.connectedConnector = null;
    }
  };

  private onChange = async (
    account: GetAccountReturnType,
    prevAccount: GetAccountReturnType
  ) => {
    // CASE OF DISCONNECTION WITH INTRUSION OF OTHER WALLET
    if (this.connectedConnector === null) {
      this._address = null;
      this._chainId = null;
      this._client = null;
      // CASE OF INTRUSION WITHOUT DISCONNECTION
    } else if (
      account.connector &&
      this.connectedConnector !== account.connector.name
    ) {
      // NOOP
    } else if (account.isConnecting) {
      // CASE OF CONNECTION IN PROGRESS -> RESET THE CLIENT
      if (this.client) {
        this._client = null;
        this._connector = null;
      }
      // CASE OF CONNECTION SUCCESS -> SET ADDRESS
    } else {
      if (account.isConnected && account.address) {
        this._address = account.address;
        this._chainId = account.chainId || null;
        this._connector = account.connector || null;

        const isInCorrectChain =
          account.chainId === this.verisModule.wagmiConfig.chains[0].id;

        // CASE OF CORRECT CHAIN -> SET CLIENT
        if (isInCorrectChain) {
          const walletClient = await getWalletClient(
            this.verisModule.wagmiConfig,
            {
              account: account.address,
              chainId: account.chainId,
              connector: account.connector,
            }
          );

          const extendedWalletClient = walletClient.extend(publicActions);

          this._client = extendedWalletClient;
        } else {
          this._client = null;
        }
      } else {
        this._address = null;
        this._chainId = null;
        this._client = null;
        this.connectedConnector = null;
      }
    }

    // console.log(`change on external wallet, new address: ${this.address}`);
    // console.log("triggering subscriptions");

    this.subscriptions.forEach((callback) => {
      callback(this.address, this.chainId, this.client, this.connector);
    });
  };

  async connect(connector: Connector) {
    this.connectedConnector = connector.name;

    try {
      await _connect(this.verisModule.wagmiConfig, {
        connector,
        chainId: this.verisModule.wagmiConfig.chains[0].id,
      });

      track.event("connect", "wallet_connected");
    } catch (err) {
      if (err instanceof ConnectorAlreadyConnectedError) {
        // THE CONNECTOR WAS ALREADY CONNECTED SO JUST REFLECT
        // THAT CONNECTION IN THE MODULE
        const account = getAccount(this.verisModule.wagmiConfig);

        this.onChange(account, account);
      } else {
        this.connectedConnector = null;

        throw err;
      }
    }
  }

  async disconnect() {
    const previousConnector = this.connectedConnector;

    this.connectedConnector = null;

    try {
      await _disconnect(this.verisModule.wagmiConfig);
    } catch (err) {
      this.connectedConnector = previousConnector;
    }
  }

  get chainId() {
    return this._chainId;
  }

  get address() {
    return this._address;
  }

  get client() {
    return this._client;
  }

  get connector() {
    return this._connector;
  }

  get connectedConnector() {
    return this.verisModule.storage.local?.get("lastConnector");
  }

  set connectedConnector(connectorName: string | null) {
    this.verisModule.storage.local?.set("lastConnector", connectorName);
  }

  getConnectors() {
    return getConnectors(this.verisModule.wagmiConfig);
  }

  async getEthBalance() {
    if (!this.address) {
      throw new Error("No address connected");
    }

    if (this.balance && Date.now() - this.balance.timestamp < 1000) {
      // console.log("cached balance used");
      return this.balance.value;
    }

    const { value } = await getBalance(this.verisModule.wagmiConfig, {
      address: this.address,
    });

    this.balance = {
      value,
      timestamp: Date.now(),
    };

    return value;
  }

  async getErc20Balance(erc20Address: Address): Promise<bigint> {
    if (!this.address) {
      throw new Error("No address connected");
    }

    if (
      this.erc20Balances[erc20Address] &&
      Date.now() - this.erc20Balances[erc20Address].timestamp < 1000
    ) {
      // console.log("cached erc20 balance used");
      return this.erc20Balances[erc20Address].value;
    }

    const value = await new Erc20Contract(
      this.verisModule,
      erc20Address
    ).balanceOf(this.address);

    this.erc20Balances[erc20Address] = {
      value,
      timestamp: Date.now(),
    };

    return value;
  }

  switchChain() {
    return switchChain(this.verisModule.wagmiConfig, {
      chainId: this.verisModule.wagmiConfig.chains[0].id,
    });
  }

  subscribe(callback: WalletSubsCallback) {
    callback(this.address, this.chainId, this.client, this.connector);

    this.subscriptions.push(callback);
  }

  unsubscribe(callback: WalletSubsCallback) {
    const index = this.subscriptions.findIndex(
      (_callback) => callback === _callback
    );

    if (index > -1) {
      this.subscriptions.splice(index, 1);
    }
  }

  async isSafeMultisigWallet() {
    if (!this.connector) {
      throw new Error("No connector connected");
    }

    const provider = (await this.connector.getProvider()) as any;

    const isSafeWallet =
      provider?.session?.peer?.metadata?.name === "Safe{Wallet}" ? true : false;

    if (isSafeWallet) {
      const info = await this.verisModule.safeService.getSafeInfo(
        this.address!
      );

      // check the amount of owners that are required to
      // sign the tx
      return info.threshold > 1;
    }

    return false;
  }

  async sendTransaction(
    to: Address,
    amount: bigint,
    options?: OptionsWriteMethod
  ): Promise<Output<void>> {
    if (!this.client) {
      throw new Error("No client connected");
    }

    return this.handleViemTransaction(
      () =>
        // @ts-ignore
        this.client!.sendTransaction({
          to,
          value: amount,
        }),
      options
    );
  }

  async handleViemTransaction<TData>(
    writeFunction: () => Promise<Hash>,
    options?: OptionsWriteMethod
  ): Promise<Output<TData>> {
    options?.onSignaturePending?.();

    let hash = await writeFunction();

    if (await this.isSafeMultisigWallet()) {
      hash = await this.verisModule.waitForSafeExecution(this.address!, hash);
    }

    options?.onLoading?.(hash);

    return this.verisModule.waitForTransactionReceipt<TData>(
      hash,
      options?.topicFilter
    );
  }

  //   private isOkxConnector(connector: Connector) {
  //     return connector.name === "OKX Wallet";
  //   }
}

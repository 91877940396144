import { VerisChain } from '@unlockdfinance/verislabs-web3';
import type { NetworkConfig } from 'app.config';
import {
  CollectionCategory,
  CollectionData,
} from 'logic/types/collection/ICollection';
import {
  CurrencyData,
  Erc20CurrencyData,
} from 'logic/types/currency/ICurrency';

const WETH_CURRENCY: Erc20CurrencyData = {
  decimals: 18,
  decimalsToShow: 4,
  srcPrimary: '/assets/images/tokens/eth.png',
  srcSecondary: '/assets/images/tokens/eth-secondary.png',
  name: 'weth',
  label: 'WETH',
  address: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
  chainlinkAddress: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
  type: 'erc20',
};

const USDC_CURRENCY: Erc20CurrencyData = {
  decimals: 6,
  decimalsToShow: 2,
  srcPrimary: '/assets/images/tokens/usdc.png',
  srcSecondary: '/assets/images/tokens/usdc-secondary.png',
  name: 'usdc',
  label: 'USDC',
  address: '0xa0b86991c6218b36c1d19d4a2e9eb0ce3606eb48',
  chainlinkAddress: '0x8fFfFfd4AfB6115b954Bd326cbe7B4BA576818f6',
  type: 'erc20',
};

const ETH_CURRENCY: CurrencyData = {
  decimals: 18,
  decimalsToShow: 4,
  srcPrimary: '/assets/images/tokens/eth.png',
  srcSecondary: '/assets/images/tokens/eth-secondary.png',
  name: 'eth',
  label: 'ETH',
  chainlinkAddress: '0x5f4eC3Df9cbd43714FE2740f5E3616155c5b8419',
  type: 'native',
};

const PUNKS_COLLECTION: CollectionData = {
  name: 'Cryptopunks',
  address: '0xb47e3cd837ddf8e4c57f05d70ab865de6e193bbb',
  genericImage: 'https://images.wrappedpunks.com/images/punks/1.png',
  currenciesSupported: [WETH_CURRENCY],
  category: CollectionCategory.PFP,
};

const UNIKURA_COLLECTION: CollectionData = {
  address: '0xEA89a88284fF9a9A9A54F4c43Fc4efbF099e992F',
  name: 'Unikura Collectibles',
  category: CollectionCategory.RWA,
  currenciesSupported: [USDC_CURRENCY],
  genericImage:
    'https://app.unikura.xyz/api/metadata/ethereum/0xEA89a88284fF9a9A9A54F4c43Fc4efbF099e992F/81391390257499604695478422093995325247002886964995876573498734894400974422878',
};

const WATCHES_COLLECTION: CollectionData = {
  address: '0xd7AB81881c8a0A8fbfDa70072D56ac6D7b3c3EfF',
  name: 'Watch-backed NFTs',
  category: CollectionCategory.RWA,
  currenciesSupported: [USDC_CURRENCY],
  genericImage:
    'https://cdn.watches.io/content/watch_images/98d1e0a9-3f90-44c0-a888-50a7ed1be03c',
};

export const mainnetConfig: NetworkConfig = {
  PUNKS_COLLECTION,
  FAUCET_COLLECTIONS: [],
  COLLECTIONS: [UNIKURA_COLLECTION, WATCHES_COLLECTION],
  CONTRACT_ADDRESSES: {
    theLockeys: '0x9a29a9DBC70eA932637216A2BF9EbE7E60023798',
    theLockeysMainnet: '0x9a29a9DBC70eA932637216A2BF9EbE7E60023798',
    weth: '0xc02aaa39b223fe8d0a0e5c4f27ead9083c756cc2',
    unlockd: '0xcD16AD66f4786a9224f53Af13987Fc2ED6FdE0Cb',
    uTokenFactory: '0x25299e9Ec27c242465587B8A2Aa70bcE02636cDA',
    walletFactory: '0xaE00dF7e92923f9c2ADCdf7C5E9F16F984cf7379',
    walletRegistry: '0x715988AfCbAEF9F99A6796f6d6699EeDDB48EB20',
    unlockdHelper: '0x42110B4F70Bd57972bCF4383b04Ce5E41F02A8a8',
    bulkTransfer: '0x0c331e00703a9857819fa1Eb72aA3d4DE0f6f725',
    pushChannel: '0x9fa5b8F350c08d7F9802cE9D7db0f322946e9C80',

    // NOT IN USE
    lockeyManager: '0x0000000000000000000000000000000000000000',
    earnWethGateway: '0x0000000000000000000000000000000000000000',
  },
  ALCHEMY: {
    rpcUrl: 'https://eth-mainnet.g.alchemy.com/v2',
    apiUrl: 'https://eth-mainnet.g.alchemy.com/nft/v2',
  },
  CHAIN: {
    name: VerisChain.MAINNET,
    hexId: '0x1',
    id: 1,
  },
  SMART_ACCOUNTS_CONFIG: {
    delegationController: '0x0000000000000000000000000000000000000000',
  },
  FUNCTIONALITIES: {
    faucet: false,
    'eth-wrapper': false,
  },
  CURRENCIES: [
    ETH_CURRENCY,
    // WETH_CURRENCY,
    USDC_CURRENCY,
  ],
  TOKENS: [ETH_CURRENCY, USDC_CURRENCY],
};
